import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Home from './pages/Home';
import RiddlesGame from './pages/RiddlesGame';
import YureiGame from './pages/YureiGame';
// import RPSGame from './pages/RPSGame';
// import RPSBlog from './pages/RPSBlog';
// import RPSHelp from './pages/RPSHelp';
// import RPSBlogPost from './pages/RPSBlogPost';



const root = ReactDOM.createRoot(document.getElementById('root'));
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/riddles",
    element: <RiddlesGame />,
  },
  {
    path: "/yurei",
    element: <YureiGame />,
  },
  // {
  //   path: "/rps",
  //   element: <RPSGame />,
  // },
  // {
  //   path: "/rpsblog",
  //   element: <RPSBlog />,
  // },
  // {
  //   path: "/rpsblog/:slug",
  //   element: <RPSBlogPost />,
  // },
  // {
  //   path: "/rpshelp",
  //   element: <RPSHelp />,
  // },
]);
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
