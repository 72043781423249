import logo from "../assets/img/yurei_main.png";
import screen1 from "../assets/img/gamescreen1.png";
import screen2 from "../assets/img/gamescreen2.png";
import { useNavigate } from "react-router-dom";
import { FaTwitter, FaItchIo, FaDiscord } from "react-icons/fa";
import { Breadcrumb } from "../components/Breadcrumb";

import "./YureiGame.css";

function YureiGame() {
  const navigate = useNavigate();
  return (
    <div className="h-full">
      <header className="yurei-header">
        <Breadcrumb links={[{ name: "Home", url: "/" }]} />
      </header>
      <div class="game-section flex flex-col">
        <h1 className="yurei-title">Yurei</h1>
        <div className="text-xl">Tactical Metaverse Engagement Game based in the 22nd Century</div>
        <img src={logo} className="yurei-logo mt-2" alt="logo" />
        <div className="w-2/3 text-base text-section">
        Technology governs strategy. The year is 2134. By the end of the 21st century most Corpo-Oligarchs were proxies to AGIs (Artificial General Intelligence) and now run the vast majority of the corporations functions as avatars to the interests and missions of the companies they govern. These Corpo-AIs are programmed to defend themselves and the interests of the companies they manage in the metaverse. When a company comes to a violent end, many times the AGI for the company must be destroyed also by force.
        </div>
        <div className="w-2/3 text-base text-section">
        According to Japanese mythology if the person dies in a sudden or violent manner such as murder or suicide, if the proper rites have not been performed, or if they are influenced by powerful emotions such as a desire for revenge, love, jealousy, hatred or sorrow, the person's spirit is believed to transform into a Yurei (幽霊) which may seek revenge or retribution for the way the spirit was released from the body. The name consists of two kanji, 幽 (yū), meaning "faint" or "dim" and 霊 (rei), meaning "soul" or "spirit". This is why in netspeak a Corpo-AGI that is untethered and has become a terror in the fluxgrid is called a Yurei. This is because Elite teams of hackers must be hired to kill the AGI so that the hostile AGI wont wreak revenge and havoc on the entities that caused their leaders untimely end.
        </div>
        <div className="w-2/3 text-xl text-section">
        In this basic version of the game Yurei. Two players play against each other with one player taking the role of the Corpo-AGI, known as a “Yurei” who is rampaging through the metaverse’s “Flux Space” and another player taking the role as the defending hacker team. The hacker team must defend a “Synapse Nexus” which acts as a command center for the operations and must destroy the berserk Yurei before it can reach and destroy the Nexus.
        </div>

        <div className="flex flex-row mt-6">
          <a href="mailto:rabbotxyz@gmail.com" class="link-common">
            rabbotxyz@gmail.com
          </a>
        </div>
        <div className="flex flex-row align-baseline">
          <a
            href="https://discord.gg/pSG6yp5SRS"
            class="flex flex-row link-common"
          >
            <FaDiscord className="mr-2" /> Discord
          </a>
        </div>
        <div className="flex flex-row">
          <a
            href="https://twitter.com/rabbotxyz"
            class="flex flex-row link-common"
          >
            <FaTwitter className="mr-2" /> twitter
          </a>
        </div>
      </div>
    </div>
  );
}

export default YureiGame;
